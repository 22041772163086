<template>
  <div>
    <el-button
      @click="dialogVisible = true"
      style="margin-bottom: 1rem"
      type="primary"
      >新增</el-button
    >
    <el-dialog
      title="提示"
      :destroy-on-close="true"
      :visible.sync="dialogVisible"
      :width="ks1 < 768 ? '90%' : '50%'"
      center
      :before-close="handleClose"
    >
      <div slot="title" class="title">新增机构</div>
      <el-form
        ref="form"
        :model="form"
        size="middle"
        class="flex dialog"
        :rules="rules"
      >
        <div class="half">
          <el-form-item label="机构名称" required prop="mechanism_name">
            <el-input
              v-model="form.mechanism_name"
              :placeholder="temp.mechanism_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-radio-group v-model="form.type" @change="getMechanismType">
              <el-radio label="teach_paypal">运动机构</el-radio>
              <el-radio label="分店">运动机构分店</el-radio>
              <el-radio label="机构商户">艺术机构</el-radio>
              <el-radio label="机构商户分店">艺术机构分店</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否社区机构" v-if="form.type != '机构商户'">
            <el-switch
              @change="communityChange"
              v-model="form.is_community"
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="所属物业" v-if="form.is_community">
            <cooperatorSelect
              :condition="{ type: 'property' }"
              :coopid.sync="coop_id"
            ></cooperatorSelect>
          </el-form-item>

          <el-form-item
            v-if="form.type == '分店'"
            label="所属机构"
            prop="education_id"
            key="education_id"
          >
            <el-select
              v-model="form.education_id"
              :remote-method="getMechanismList"
              remote
              filterable
              @change="log"
              :placeholder="getMechanismName(temp.education_id) || '请选择机构'"
            >
              <el-option
                v-for="item in mechanism_list"
                :key="item.id"
                :label="item.mechanism_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否独立负责人" v-if="form.type == '分店'">
            <el-checkbox v-model="if_admin"></el-checkbox>
          </el-form-item>
          <el-form-item label="是否推荐">
            <el-radio-group v-model="form.is_recommend">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="运营方" required>
            <el-radio-group v-model="form.operate">
              <el-radio label="settle">机构运营</el-radio>
              <el-radio label="haima_self">官方直营</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="省市区" required prop="city">
            <city-select @change="changeCity" :temp="cityList"></city-select>
          </el-form-item>
          <el-form-item label="详细地址" required>
            <el-input v-model="form.mechanism_addr">
              <addr-select
                slot="append"
                @getform="addMecAddr"
                type="icon"
              ></addr-select>
            </el-input>
          </el-form-item>
          <el-form-item label="经度">
            <el-input v-model="form.longitude" placeholder="经度"></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input v-model="form.latitude" placeholder="纬度"></el-input>
          </el-form-item>
          <el-form-item
            label="机构电话"
            required
            prop="mechanism_telephone"
            key="mechanism_telephone"
          >
            <el-input
              v-model="form.mechanism_telephone"
              :placeholder="temp.mechanism_telephone"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="负责人姓名"
            required
            v-if="if_admin || form.type != '分店'"
            prop="contacts"
            key="contacts"
          >
            <el-input
              v-model="form.contacts"
              :placeholder="temp.contacts"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="负责人职称"
            v-if="form.type == '分店' && if_admin"
          >
            <el-input
              v-model="form.contacts_title"
              :placeholder="temp.contacts_title"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系电话"
            required
            v-if="if_admin || form.type != '分店'"
            prop="contact_telephone"
            key="contact_telephone"
          >
            <el-input
              v-model="form.contact_telephone"
              :placeholder="temp.contact_telephone"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="支付宝收款账户" required prop="payee_logon_id"> -->
          <!-- <el-form-item label="支付宝收款账户">
            <el-input v-model="form.payee_logon_id" :placeholder="temp.payee_logon_id"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="机构类别" required>
            <el-select
              v-model="form.categories"
              @focus="searchCatName"
              remote
              clearable
              :remote-method="searchCatName"
            >
              <el-option
                v-for="(item, index) in categories_list"
                :key="index"
                :value="item.name"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="选择子类目" required>
            <el-popover
              placement="top-start"
              title="标题"
              trigger="click"
              v-model="visible"
            >
              <el-button slot="reference">{{
                haveCate.length == 0 ? "选择子类目" : haveCate.join(",")
              }}</el-button>

              <el-transfer
                filterable
                filter-placeholder="请选择绑定的科目"
                v-model="haveCate"
                :data="categories_child_list"
                :props="{
                  key: 'name',
                  label: 'name',
                }"
                :titles="['所有类目', '拥有类目']"
              >
              </el-transfer>
            </el-popover>
          </el-form-item>
          <el-form-item label="告警课时">
            <el-input
              v-model="form.warning_hours"
              placeholder="剩余多少课时时发出告警"
            >
            </el-input>
          </el-form-item>
        </div>
        <div class="half">
          <el-form-item label="机构简介">
            <el-input
              style="width: inherit"
              type="textarea"
              v-model="form.introduction_content"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构logo">
            <avatar-uploader
              @getImgUrl="getFaceView"
              :clear="if_clear"
              :url="temp.logo"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item
            label="营业执照"
            required
            prop="support_means"
            ref="support"
          >
            <avatar-uploader
              @getImgUrl="getSupportImg"
              :clear="if_clear"
              :url="temp.support_means"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="机构资质">
            <images-uploader
              @getImgUrl="get_quali_pic_url"
              :clear="if_clear"
              :urls="temp.qualifications_pic"
            >
            </images-uploader>
          </el-form-item>
          <el-form-item label="简介图片">
            <images-uploader
              @getImgUrl="get_introduce_pic_url"
              :clear="if_clear"
              :urls="temp.introduction_pic"
            >
            </images-uploader>
          </el-form-item>
          <el-form-item label="机构门面图">
            <images-uploader
              @getImgUrl="get_facade_view_url"
              :clear="if_clear"
              :urls="temp.facade_view"
            >
            </images-uploader>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="isLoading" type="primary" @click="submitForm()"
          >添 加</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="修改机构"
      :visible="updateDialog()"
      :width="ks1 < 768 ? '95%' : '50%'"
      center
      :before-close="handleClose"
    >
      <el-form :model="form" size="middle" class="flex dialog">
        <div class="half">
          <el-form-item label="机构名称" required>
            <el-input
              v-model="form.mechanism_name"
              :placeholder="temp.mechanism_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group
              v-model="form.type"
              @change="getMechanismType"
              disabled
            >
              <el-radio label="teach_paypal">机构</el-radio>
              <el-radio label="机构商户">艺术机构</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否社区机构" v-if="form.type == 'teach_paypal'">
            <el-tag v-if="form.is_community" size="small" type="success"
              >社区机构</el-tag
            >
            <div v-else>非社区机构</div>
          </el-form-item>
          <el-form-item label="所属物业" v-if="form.is_community">
            <cooperatorSelect
              :condition="{ type: 'property' }"
              :coopid.sync="coop_id"
              :info="form.map ? form.map.cooperator_name : ''"
            ></cooperatorSelect>
          </el-form-item>
          <el-form-item label="是否分店">
            <el-checkbox v-model="if_part" disabled></el-checkbox>
          </el-form-item>
          <el-form-item label="是否独立负责人">
            <el-checkbox v-model="if_admin" disabled></el-checkbox>
          </el-form-item>
          <el-form-item v-if="form.type == '分店'" label="所属机构" required>
            <el-select
              v-model="form.education_id"
              :remote-method="getMechanismList"
              remote
              filterable
              @change="log"
              :placeholder="getMechanismName(temp.education_id) || '请选择机构'"
            >
              <el-option
                v-for="item in mechanism_list"
                :key="item.id"
                :label="item.mechanism_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运营方" required>
            <el-radio-group v-model="form.operate">
              <el-radio label="settle">机构运营</el-radio>
              <el-radio label="haima_self">官方直营</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否推荐">
            <el-radio-group v-model="form.is_recommend">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="选择地区" required>
            <addr-select :clear="if_clear" @getform="addMecAddr"
              :addr='{province:temp.province,city:temp.city,district:temp.district}'></addr-select>
          </el-form-item> -->
          <el-form-item label="省市区" required>
            <city-select @change="changeCity" :temp="cityList"></city-select>
          </el-form-item>
          <el-form-item label="详细地址" key="ed" required>
            <el-input v-model="form.mechanism_addr">
              <addr-select
                slot="append"
                @getform="addMecAddr"
                type="icon"
              ></addr-select>
            </el-input>
          </el-form-item>
          <el-form-item label="经度">
            <el-input v-model="form.longitude" placeholder="经度"></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input v-model="form.latitude" placeholder="纬度"></el-input>
          </el-form-item>
          <el-form-item label="机构电话" required v-if="if_admin">
            <el-input
              v-model="form.mechanism_telephone"
              :placeholder="temp.mechanism_telephone"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人姓名" required v-if="if_admin">
            <el-input
              v-model="form.contacts"
              :placeholder="temp.contacts"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人职称" required v-if="if_part && if_admin">
            <el-input
              v-model="form.contacts_title"
              :placeholder="temp.contacts_title"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" required v-if="if_part && if_admin">
            <el-input
              v-model="form.contact_telephone"
              :placeholder="temp.contact_telephone"
            ></el-input>
          </el-form-item>
          <el-form-item label="支付宝账户" required>
            <el-input
              v-model="form.payee_logon_id"
              :placeholder="temp.payee_logon_id"
            ></el-input>
          </el-form-item>

          <el-form-item label="选择子类目" required>
            <el-popover
              placement="top-start"
              title="标题"
              trigger="click"
              @after-leave="popoverHide"
              v-model="visible"
            >
              <el-button slot="reference">{{
                haveCate.length == 0 ? "选择子类目" : haveCate.join(",")
              }}</el-button>
              <el-transfer
                filterable
                filter-placeholder="请选择绑定的科目"
                v-model="haveCate"
                :data="categories_child_list"
                :props="{
                  key: 'name',
                  label: 'name',
                }"
                :titles="['所有类目', '拥有类目']"
              >
              </el-transfer>
            </el-popover>
          </el-form-item>
        </div>
        <div class="half">
          <el-form-item label="机构简介">
            <el-input
              style="width: inherit"
              type="textarea"
              v-model="form.introduction_content"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构logo">
            <avatar-uploader
              @getImgUrl="getFaceView"
              :clear="if_clear"
              :url="temp.mechanism_logo"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="营业执照" required>
            <avatar-uploader
              @getImgUrl="getSupportImg"
              :clear="if_clear"
              :url="temp.support_means"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="机构资质">
            <images-uploader
              @getImgUrl="get_quali_pic_url"
              :clear="if_clear"
              :urls="temp.qualifications_pic"
            >
            </images-uploader>
          </el-form-item>
          <el-form-item label="简介图片">
            <images-uploader
              @getImgUrl="get_introduce_pic_url"
              :clear="if_clear"
              :urls="temp.introduction_pic"
            >
            </images-uploader>
          </el-form-item>
          <el-form-item label="机构门面图">
            <images-uploader
              @getImgUrl="get_facade_view_url"
              :clear="if_clear"
              :urls="temp.facade_view"
            >
            </images-uploader>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="updateVisible" @click="update"
          >修改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ObjectChangedValue } from "@/js/utils.js";
import citySelect from "./select/citySelect.vue";
import cooperatorSelect from "./select/cooperatorSelect.vue";
export default {
  props: {
    updateVisible: {
      type: Boolean,
    },
    temp: {
      type: Object,
    },
    ks1: {},
  },
  watch: {
    temp: {
      deep: true,
      handler(newV, oldV) {
        if (Object.keys(newV).length) {
          this.form = Object.assign({}, newV);
          this.haveCate = newV.categories_child
            ? newV.categories_child.split("/")
            : [];
          if (newV.education_id) {
            this.if_part = true;
          }
          if (newV.contact_telephone) {
            this.if_admin = true;
          }
          this.originObj = Object.assign({}, newV);
          this.coop_id = "";
          this.cityList.push(newV.province);
          this.cityList.push(newV.city);
          this.cityList.push(newV.district);
        }
      },
    },
    // if_part(newV, oldV) {
    //   if (!newV) {
    //     this.if_admin = false

    //     this.$set(this.form, 'education_id', null)
    //   }
    // },
    if_admin(newV, oldV) {
      if (!newV) {
        this.$set(this.form, "contact_telephone", null);
        this.$set(this.form, "contacts", null);
      }
    },
  },
  components: {
    // searchAddr,
    citySelect,
    cooperatorSelect,
  },
  data() {
    var checkSupport = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请上传营业执照!"));
      }
      callback();
    };
    return {
      isLoading: false,
      placeholder: [],
      dialogVisible: false,
      address: {},
      if_admin: false,
      if_clear: true,
      categories_list: [],
      if_part: false,
      mechanism_list: [],
      categories_child_list: [],
      haveCate: [],
      visible: false,
      form: { operate: "settle" },
      originObj: {},
      cityList: [],
      coop_id: "",
      rules: {
        mechanism_name: [
          { required: true, message: "机构名不能为空!", trigger: "blur" },
          { required: true, message: "机构名不能为空!", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择机构类型!", trigger: "change" },
        ],
        mechanism_id: [
          { required: true, message: "请选择机构!", trigger: "blur" },
          { required: true, message: "请选择机构!", trigger: "change" },
        ],
        city: [{ required: true, message: "请选择城市", trigger: "change" }],
        mechanism_addr: [
          { required: true, message: "请选择详细地址!", trigger: "change" },
        ],
        categories_child: [
          { required: true, message: "请选择类目!", trigger: "change" },
        ],
        mechanism_telephone: [
          { required: true, message: "请输入机构电话!", trigger: "change" },
          { required: true, message: "请输入机构电话!", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请填入负责人姓名!", trigger: "blur" },
          { required: true, message: "请填入负责人姓名!", trigger: "change" },
        ],
        contact_telephone: [
          { required: true, message: "请填入负责人联系方式!", trigger: "blur" },
          {
            required: true,
            message: "请填入负责人联系方式!",
            trigger: "change",
          },
        ],
        // payee_logon_id: [
        //   { required: true, message: '请填入支付宝收款账号!', trigger: 'blur' },
        //   { required: true, message: '请填入支付宝收款账号!', trigger: 'change' },
        // ],
        support_means: [{ validator: checkSupport, trigger: "change" }],
        education_id: [
          { required: true, message: "请选择所属机构!", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.getCategoriesList();
  },

  methods: {
    communityChange(v) {
      if (v) {
        this.form.operate = "haima_self";
      }
    },
    getLng() {
      this.$map.then((AMap) => {
        let geco = new AMap.Geocoder();
        let address = this.geco.getLocation(address, function (status, result) {
          if (status === "complete" && result.geocodes.length) {
            var lnglat = result.geocodes[0].location;
          } else {
            log.error("根据地址查询位置失败");
          }
        });
      });
    },
    changeCity(v) {
      this.$set(this.form, "province", v[0]);
      this.$set(this.form, "city", v[1]);
      this.$set(this.form, "district", v[2]);
      this.cityList = v;
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.addNewOrg();
        } else {
          this.$message({
            type: "error",
            message: "请填写必要信息!",
          });
        }
      });
    },
    updateDialog() {
      return this.updateVisible;
    },
    popoverHide() {
      console.log(1);
      this.visible = false;
    },
    getMechanismType(v) {
      this.getCategoriesList();
      this.is_part = false;
      this.is_admin = false;
    },
    log(id) {
      console.log(id);
    },
    getMechanismName(id) {
      let a = this.mechanism_list.find((item) => {
        return item.id == id;
      });
      if (a) {
        return a.mechanism_name;
      } else return null;
    },
    getSupportImg(v) {
      this.$set(this.form, "support_means", v);
      this.$refs.support.$emit("el.form.change", this.form.support_means);
    },
    getFaceView(v) {
      this.$set(this.form, "mechanism_logo", v);
    },
    get_quali_pic_url(v) {
      this.$set(this.form, "qualifications_pic", v);
    },
    get_facade_view_url(v) {
      this.$set(this.form, "facade_view", v);
    },
    get_introduce_pic_url(v) {
      this.$set(this.form, "introduction_pic", v);
    },
    // 新增机构地址
    addMecAddr(addr) {
      if (addr.address) {
        this.cityList = [];
        this.$set(this.form, "province", addr.province);
        this.$set(this.form, "city", addr.city);
        this.$set(this.form, "district", addr.district);
        this.$set(this.form, "mechanism_addr", addr.address);
        this.cityList.push(addr.province);
        this.cityList.push(addr.city);
        this.cityList.push(addr.district);
      }

      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
    },
    getCategoriesList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 2,
            source: this.form.type == "机构商户" ? "休闲生活" : "课程",
          },
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },
    searchCatName(query) {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            type: 1,
            status: 2,
            source: "课程",
          },
        })
        .then((res) => {
          // console.log(res);
          this.categories_list = res.data.data;
        });
    },
    // 新增弹窗关闭确认
    handleClose(done) {
      this.if_clear = false;
      this.$confirm("确认关闭？")
        .then((_) => {
          this.form = { operate: "settle" };
          // this.if_part = false
          this.dialogVisible = false;
          this.$emit("close", { if_change: false });
          this.if_clear = true;
          done();
        })
        .catch((_) => {
          console.log(_);
        });
    },
    getMechanismList(query) {
      let url = "/user/mastermechanism/queryByMessage";
      let param = {
        // type: this.form.type,
        status: 2,
        // is_cooperation: true,
        currentPage: 1,
        pageSize: 10,
        mechanism_name: typeof query == "string" ? query : null,
        parent_id: 0,
        // source:'体育宝',
      };
      this.$axios
        .get(url, {
          params: param,
        })
        .then((res) => {
          // console.log(res.data);
          this.mechanism_list = res.data.data.rows;
        })
        .catch((rej) => {});
    },
    // 新增机构
    addNewOrg() {
      this.if_clear = false;
      let url;
      if (this.form.type == "分店" && !this.if_admin) {
        url = "/user/mastermechanism/insertAppointmentMechanismInfoBranches";
      } else {
        url = "/user/mastermechanism/insertAppointmentMechanismInfo";
      }
      if (this.cityList.length > 0) {
        this.form.province = this.cityList[0];
        this.form.city = this.cityList[1];
        this.form.district = this.cityList[2];
      }
      this.form.cooperator_id = this.coop_id || 0;
      this.form.categories =
        this.form.type == "机构商户" ? "休闲生活" : "体育运动";
      this.form.is_cooperation = this.form.type == "分店" ? true : null;
      // this.$set(this.form, 'mechanism_addr', this.form.addr)
      // this.is_support_teach_paypal = true
      this.form.categories_child = this.haveCate.join(",");
      this.form.status = 2;
      this.form.source = "体育宝";
      let data = { ...this.form };
      if (this.if_admin && this.form.type == "分店") {
        data.type = "分店1";
      }
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          this.isLoading = false;
          // this.$refs.addrSelect.clear();
          if (res.data.code != 0) {
            this.$message({ type: "error", message: res.data.message });
          } else {
            this.$message({ type: "success", message: "添加成功" });
            this.cityList = [];
            this.dialogVisible = false;
            // console.log(res);

            // this.$message({ type: "success", message: "添加成功!" });
            this.form = { operate: "settle" };
            this.if_clear = true;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    update() {
      this.form.categories_child =
        this.haveCate != [] ? this.haveCate.join(",") : null;
      this.form.status = 2;
      this.form.cooperator_id = this.coop_id || 0;
      if (this.cityList.length > 0) {
        this.form.province = this.cityList[0];
        this.form.city = this.cityList[1];
        this.form.district = this.cityList[2];
      }
      const data = ObjectChangedValue(this.originObj, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }

      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          id: this.form.id,
          ...data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.form = {};
            this.temp = {};
            this.$emit("close", { if_change: true });
            this.$message({ type: "warning", message: "修改成功!" });
          }
        });
    },
  },
};
</script>

<style>
.half {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.dialog {
  padding: 0 30px;
}
</style>
